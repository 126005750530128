.Notification {
    display: block;
    position: absolute;
    align-self: center;
    top: 50%;
    padding: 8px 18px;
    border-radius: 4px;
    background-color: #d69d00;
}

.Notification__ellipsis:after {
    display: inline-block;
    animation: ellipsis steps(1,end) 1s infinite;
    content: '';
    width: 15px;
}

@keyframes ellipsis {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}
