.amount-input {
    vertical-align: middle;
    position: relative;
    display: flex;
}
.amount-input .input-btn,
.amount-input .input {
    display: inline-block;
    height: 40px;
    line-height: 38px;
    box-sizing: border-box;
    vertical-align: middle;
    z-index: 2;
}
.amount-input .input-btn:hover,
.amount-input .input:hover {
    z-index: 3;
}
.amount-input .input {
    margin-left: -1px;
    flex: 1 1 100%;
}
.amount-input .input i {
    position: absolute;
    left: 55px;
    font-style: normal;
}
.amount-input .input-btn {
    border: 1px solid #414a60;
    width: 40px;
    flex: 1 0 40px;
    text-align: center;
    font-weight: 100;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    user-select: none;
}
.amount-input .input-btn:hover {
    border-color: #555e74;
    z-index: 3;
}
.amount-input .plus {
    border-radius: 0 3px 3px 0;
    margin-left: -1px;
    line-height: 36px;
}
.amount-input .minus {
    border-radius: 3px 0 0 3px;
    line-height: 36px;
}
.amount-input .plus,
.amount-input .minus {
    transition: 0.3s background-color;
}
.amount-input .plus:hover,
.amount-input .minus:hover {
    background-color: #2f3a52;
}
.amount-input input {
    background: none;
    vertical-align: top;
    display: inline-block;
    color: #fff;
    font-size: 1em;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 14px 2px 28px;
    border-radius: 0;
    width: 100%;
    outline: none;
    border: 1px solid #414a60;
    position: relative;
    z-index: 1;
}
.amount-input input:hover {
    border-color: #555e74;
    z-index: 2;
}
.amount-input input:focus {
    border-color: #3099f5;
    z-index: 4;
}

.deal-controller {
    direction: ltr;
    position: absolute;
    bottom: 75px;
    left: 50%;
    margin-left: -262.5px;
    width: 400px;
    height: 80px;
    border-radius: 5px;
    background-color: rgba(28,35,51,0.6);
    z-index: 10;
    padding: 0 10px;
    box-sizing: border-box;
    transition: 0.2s width, 0.2s margin;
}
.deal-controller .trader-choice-tooltip {
    position: absolute;
    display: block;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
}
.deal-controller .current-deal .currency-double i {
    left: 49px;
}
.deal-controller .current-deal .currency-double input {
    padding: 0 10px 2px 30px;
}
.deal-controller .current-deal,
.deal-controller .new-deal {
    position: relative;
    height: 90px;
    box-sizing: border-box;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    width: calc(100% + 10px);
}
.deal-controller .holder {
    position: relative;
    overflow: hidden;
    height: 90px;
}
.deal-controller .holder .slider {
    height: 140px;
    transition: 0.2s;
}
.deal-controller .holder .slider.upper {
    transform: translateY(0);
}
.deal-controller .holder .slider.down {
    transform: translateY(-90px);
}
.deal-controller .block {
    display: block;
    height: 70px;
    box-sizing: border-box;
    margin-right: 10px;
}
.deal-controller .block.amount {
    width: 170px;
    box-sizing: border-box;
}
.deal-controller .block:first-of-type {
    margin-left: 0;
}
.deal-controller .block .title {
    font-size: 11px;
    margin-bottom: 4px;
    color: #8f9ab2;
    font-weight: 600;
}
.deal-controller .block.deal {
    display: flex;
    padding-right: 0;
    flex: 2 0 33%;
}
.deal-controller .block.deal > div {
    width: 50%;
    box-sizing: border-box;
}
.deal-controller .block.deal > div .amount-data {
    font-size: 11px;
    margin-bottom: 4px;
    color: #8f9ab2;
}
.deal-controller .block.deal > div .amount-data .money-tiny {
    font-size: 9px;
    margin-bottom: 3px;
}
.deal-controller .block.deal > div.put-side {
    padding-right: 4pt;
}
.deal-controller .block.deal > div.call-side {
    padding-left: 4pt;
}
.deal-controller .block.deal > div .deal-button {
    cursor: pointer;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    transition: transform 0.2s;
    position: relative;
    font-weight: 700;
    font-size: 13px;
    color: rgba(0,0,0,0.3);
    box-sizing: border-box;
    user-select: none;
}
.deal-controller .block.deal > div .deal-button:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    content: '';
    display: block;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.2s;
}
.deal-controller .block.deal > div .deal-button:hover:after {
    opacity: 0.2;
}
.deal-controller .block.deal > div .deal-button:active {
    transform: translateY(1px);
}
.deal-controller .block.deal > div .deal-button.call {
    background-image: linear-gradient(-180deg, #8cc44f 0%, #4ed462 100%);
}
.deal-controller .block.deal > div .deal-button.put {
    background-image: linear-gradient(-180deg, #d4225d 0%, #d42260 100%);
}
.deal-controller .block.deal > div .deal-button.disabled {
    opacity: 0.25;
    pointer-events: none;
}
.deal-controller .new {
    border: 1px dashed #3099f5;
    border-radius: 3px;
    height: 60px;
    line-height: 52px;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 26px;
    font-weight: 200;
    color: #fff;
    transition: 0.2s background-color;
    cursor: pointer;
    flex-basis: 50%;
}
.deal-controller .new i {
    color: #3099f5;
    font-size: 30px;
    font-style: normal;
}
.deal-controller .new:hover {
    background-color: rgba(48,153,245,0.1);
}
.deal-controller .amount .value {
    font-size: 1.4em;
}
.deal-controller .profit {
    vertical-align: bottom;
    max-width: 102px;
}
.deal-controller .profit .value {
    line-height: 40px;
    height: 40px;
    width: 100px;
    display: block;
    overflow: hidden;
}
.deal-controller .profit .value.money-big {
    font-size: 24px;
}
.deal-controller .profit .value.money-medium {
    font-size: 19px;
}
.deal-controller .profit .value.money-small {
    font-size: 15px;
}
.deal-controller .profit .value.money-tiny {
    font-size: 13px;
}
.deal-controller .strike-select {
    width: 168px;
}
.deal-controller .strike-select .amount-input {
    display: flex;
}
.deal-controller .strike-select .amount-input .plus,
.deal-controller .strike-select .amount-input .minus {
    font-size: 6px;
}
.deal-controller .strike-select .amount-input .plus:before {
    display: block;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\E95A";
}
.deal-controller .strike-select .amount-input .minus:before {
    display: block;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\E959";
}
.deal-controller .strike-select .amount-input .input {
    flex-grow: 2;
    border: 1px solid #414a60;
    font-size: 12px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    transition: 0.3s background-color;
}
.deal-controller .strike-select .amount-input .input:hover {
    background-color: #2f3a52;
    border: 1px solid #4b546a;
    z-index: 3;
}
.deal-controller .strike-select .amount-input .input .value {
    position: relative;
    box-sizing: border-box;
    padding-left: 20px;
}
.deal-controller .strike-select .amount-input .input .value.money-big {
    font-size: 14px;
}
.deal-controller .strike-select .amount-input .input .value.money-medium {
    font-size: 14px;
}
.deal-controller .strike-select .amount-input .input .value.money-small {
    font-size: 14px;
}
.deal-controller .strike-select .amount-input .input .value.money-tiny {
    font-size: 12px;
}
.deal-controller .timer {
    width: auto;
    vertical-align: bottom;
    margin: 0;
}
.deal-controller .timer .value {
    line-height: 40px;
    height: 40px;
    overflow: hidden;
    font-size: 24px;
}
