.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: var(--header-color);
  min-height: 50px;
  display: flex;
  align-items: stretch;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 50px;
  width: 45px;
  margin-left: 5px;
  pointer-events: none;
}

.App-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.App-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 20px;
  color: white;
  text-decoration: none;
}

.App-footer {
  background-color: var(--background-color);
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: white;
}

.App-main-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.App-sidebar-left,
.App-sidebar-right {
  background-color: var(--background-color);
  min-width: 0px;
  display: flex;
}

.App-main {
  background-color: #343d50;
  flex-grow: 1;
}

